import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from '../components/layout';
import Page from '../components/Page';
import formatDate from '../utils/formatDate';
import SEO from '../components/seo';

const Byline = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 100;
`;

const PostDate = styled.div`
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 3px;
`;

const PostTemplate = ({ pageContext: context, uri }) => {
  const {
    featuredImage, content, title, author, date, seo,
  } = context;
  // Set to Default Background Image
  const backgroundImage = featuredImage
    ? featuredImage.sourceUrl
    : '';

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        type="article"
        image={backgroundImage}
        url={uri}
      />
      <Page.Wrapper>
        <Page.Image img={backgroundImage} />
        <Page.Content top>
          <Page.Title>{title}</Page.Title>
          <PostDate>
            {formatDate(date)}
          </PostDate>
          <Byline>
            By
            {' '}
            <span>
              {author.firstName}
              {' '}
              {author.lastName}
            </span>
          </Byline>

          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Page.Content>
      </Page.Wrapper>
    </Layout>
  );
};

PostTemplate.defaultProps = {};

PostTemplate.propTypes = {
  uri: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    featuredImage: PropTypes.shape({
      sourceUrl: PropTypes.string,
    }),
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default PostTemplate;
